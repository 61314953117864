import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/index.vue'),
    meta: {
      title: "斗转多开"
    }
  },{
    path: '/one',
    name: 'One',
    component: () => import('../views/one.vue'),
    meta: {
      title: "内置版模拟定位"
    }
  },{
    path: '/two',
    name: 'Two',
    component: () => import('../views/two.vue'),
    meta: {
      title: "内置版模拟路线"
    }
  },{
    path: '/three',
    name: 'Three',
    component: () => import('../views/three.vue'),
    meta: {
      title: "独立版模拟路线"
    }
  },{
    path: '/wentia',
    name: 'Wentia',
    component: () => import('../views/wentia.vue'),
    meta: {
      title: "版本更新注意事项"
    }
  },{
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/privacy.vue'),
    meta: {
      title: "斗转多开隐私政策"
    }
  },{
    path: '/collection',
    name: 'Collection',
    component: () => import('../views/collection.vue'),
    meta: {
      title: "个人信息收集清单"
    }
  },{
    path: '/permission',
    name: 'Permission',
    component: () => import('../views/permission.vue'),
    meta: {
      title: "设备权限调用"
    }
  },{
    path: '/server',
    name: 'Server',
    component: () => import('../views/server.vue'),
    meta: {
      title: "设备权限调用"
    }
  },{
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/feedback.vue'),
    meta: {
      title: "用户反馈"
    }
  },{
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/notice.vue'),
    meta: {
      title: "注销用户须知"
    }
  },{
    path: '/dzdk',
    name: 'Dzdk',
    component: () => import('../views/dzdk.vue'),
    meta: {
      title: "斗转多开"
    }
  },{
    path: '/zj',
    name: 'Zj',
    component: () => import('../views/zj.vue'),
    meta: {
      title: "资质证照"
    }
  },{
    path: '/yyzz',
    name: 'Yyzz',
    component: () => import('../views/yyzz.vue'),
    meta: {
      title: "营业执照"
    }
  },{
    path: '/zgcgxjsqy',
    name: 'Zgcgxjsqy',
    component: () => import('../views/zgcgxjsqy.vue'),
    meta: {
      title: "中关村高新技术企业"
    }
  },{
    path: '/icp',
    name: 'Icp',
    component: () => import('../views/icp.vue'),
    meta: {
      title: "增值电信业务经营许可证"
    }
  },{
    path: '/zzq',
    name: 'Zzq',
    component: () => import('../views/zzq.vue'),
    meta: {
      title: "计算机软件著作权登记证书"
    }
  },{
    path: '/appba',
    name: 'Appba',
    component: () => import('../views/appba.vue'),
    meta: {
      title: "斗转多开备案信息"
    }
  },{
    path: '/sdk',
    name: 'Sdk',
    component: () => import('../views/sdk.vue'),
    meta: {
      title: "第三方sdk说明"
    }
  },{
    path: '/tzprivacy',
    name: 'tzPrivacy',
    component: () => import('../views/tzprivacy.vue'),
    meta: {
      title: "天征语音模型隐私政策"
    }
  },{
    path: '/tzsdk',
    name: 'tzSdk',
    component: () => import('../views/tzsdk.vue'),
    meta: {
      title: "第三方sdk说明"
    }
  },{
    path: '/tzserver',
    name: 'tzServer',
    component: () => import('../views/tzserver.vue'),
    meta: {
      title: "天征语音模型用户服务协议"
    }
  },{
    path: '/tznotice',
    name: 'tzNotice',
    component: () => import('../views/tznotice.vue'),
    meta: {
      title: "注销用户须知"
    }
  }, {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      title: "页面没有找到"
    }
  },
  {
    path: "*",
    redirect: "/404"
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router